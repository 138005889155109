import { getUrlParam } from '../utils/url';
import Event from '../events/event';
import listenErrors from './ErrorListener';
import URLs from '../api/URLs';

const eStatisticEventId = {
  ESEI_PING: 'ping',
  ESEI_AUTH: 'auth',
  ESEI_FBET: 'fbet',
  ESEI_CUSTOM: 'custom',
  ESEI_ERROR: 'error',
  ESEI_CONSOLE_ERROR: 'consoleError',
  ESEI_DOWNLOAD_COMPLETE: 'complete'
};

export default class ControllerStatistic {
  constructor() {
    this._data = {
      userAgent: window.navigator.userAgent,
      lastWinAmount: 0,
      totalWinAmount: 0,
      lastBetAmount: 0,
      totalBetAmount: 0,
      spinCount: 0,
      autoBetCount: 0,
      freeBetsCount: 0,
      buyBonusCount: 0,
      orientationTimes: {
        portrait: 0,
        landscape: 0
      },
      sessionTime: 0,
      downloadTime: 0,
      educationComplete: false,
      educationStepCount: 0,
      heatmapData: [],
      errorCodes: [],
      errorMessages: [],
      betCountByIndex: {},
      autoBetCountByIndex: {},
      buyBonusCountByIndex: {}
    };
    this._isFirstBet = true;
    this._bundle = '';
    this._downloadStartTime = Date.now();
    this._orintationStartTime = this._downloadStartTime;
    this._onUpdate = new Event();
    this.connect();
    this._addErrorsListeners();
  }

  _addErrorsListeners() {
    if (console.everything === undefined) {
      console.everything = [];
      listenErrors((errorData, errorCode) => {
        if (errorCode) this._data.errorCodes.push(errorCode);
        this._data.errorMessages.push(errorData);
        console.everything.push(errorData)
        this.sendConsoleError(errorData);
      });
    }
  }

  init(partnerConfig, config) {
    if (partnerConfig.hasOwnProperty('maxWin')) {
      this._winLimit = parseFloat(partnerConfig.maxWin);
    }
    if (config && config.hasOwnProperty('bundle')) {
      this.bundle = config.bundle;
    }
  }

  watchClicks() {
    window.OPWrapperService.eventManager.add(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, (data) => {
      this._activeHeatmapItem = {
        width: data.innerWidth,
        height: data.innerHeight,
        points: []
      }
    });

    document.documentElement.addEventListener('click', (e) => {
      const point = {
        x: Math.round(e.clientX) || 0,
        y: Math.round(e.clientY) || 0,
      }
      if (this._activeHeatmapItem && this._data.heatmapData[this._data.heatmapData.length - 1] !== this._activeHeatmapItem) {
        this._data.heatmapData.push(this._activeHeatmapItem);
      }
      if (this._activeHeatmapItem) this._activeHeatmapItem.points.push(point);
    });
  }

  connect() {
    this.ws = new WebSocket(URLs.getURL(URLs.eURLsNames.EUN_WSS_STATISTIC_URL));
    // this.ws = new WebSocket(`ws://localhost:12010/stat`);
    this.ws.addEventListener('message', this.onMessage.bind(this));
    this.ws.addEventListener('open', this.onOpen.bind(this));
  }

  onMessage(event) {
    let data;
    data = JSON.parse(event.data);
    if (data.id === eStatisticEventId.ESEI_PING) {
      this.send({ id: eStatisticEventId.ESEI_PING });
    }
  }

  onOpen() {
    this.authorize();
    this.sendSavedErrors();
    if (this._gameIsdownload) {
      this.sendDownloadCompleteData();
    }
  }

  authorize() {
    const sid = getUrlParam('sid');
    const gid = getUrlParam('gid');
    const location = window.location.origin + window.location.pathname;
    this.send({ id: eStatisticEventId.ESEI_AUTH, data: { sid, gid, location } });
  }

  sendSavedErrors() {
    console.everything.forEach(errorData => {
      this.sendConsoleError(errorData);
    })
  }

  sendDownloadCompleteData() {
    this.send({ id: eStatisticEventId.ESEI_CUSTOM, type: eStatisticEventId.ESEI_DOWNLOAD_COMPLETE, data: {
        downloadTime: this._data.downloadTime
      }});
  }

  sendConsoleError(errorData) {
    try {
      const data = { id: eStatisticEventId.ESEI_CUSTOM, type: eStatisticEventId.ESEI_CONSOLE_ERROR, data: errorData };
      this.send(data);
    } catch (e) {

    }
  }

  sendError(code, messageFallback) {
    try {
      const data = { id: eStatisticEventId.ESEI_CUSTOM,
        type: eStatisticEventId.ESEI_CONSOLE_ERROR,
        data: {
          errorShownInWrapperModal: true,
          code,
          message: messageFallback
        }
      };
      this.send(data);
    } catch (e) {
      console.error(e);
    }
  }

  send(data) {
    if (this.ws.readyState === WebSocket.OPEN) this.ws.send(JSON.stringify(data));
  }

  sendCustomEvent(object) {
    if (!object.hasOwnProperty('id')) {
      object.id = eStatisticEventId.ESEI_CUSTOM;
    }
    if (!object.hasOwnProperty('bundle')) {
      object.bundle = this.bundle;
    }

    this.send(object);
  }

  sendFirstBet(betAmount) {
    this.send({ id: eStatisticEventId.ESEI_CUSTOM, type: eStatisticEventId.ESEI_FBET, data: {
        betAmount,
        bundle: this.bundle
    }});
  }

  increaseSpinCount(betIndex, isAutoBet = false) {
    if (betIndex >= 0) this._data.betCountByIndex[betIndex] = (this._data.betCountByIndex[betIndex] + 1) || 1;
    if (isAutoBet && betIndex >= 0) this._data.autoBetCountByIndex[betIndex] = (this._data.autoBetCountByIndex[betIndex] + 1) || 1;
    if (isAutoBet) this.autoBetCount++;
    this.spinCount++;
  }

  increaseFreeBetsCount() {
    this.freeBetsCount++;
  }

  decreaseSpinCount(betIndex, isAutoBet = false) {
    if (betIndex >= 0) this._data.betCountByIndex[betIndex] = (this._data.betCountByIndex[betIndex] - 1) || 0;
    if (isAutoBet && betIndex >= 0) this._data.autoBetCountByIndex[betIndex] = (this._data.autoBetCountByIndex[betIndex] - 1) || 0;
    if (isAutoBet) this.autoBetCount--;
    this.spinCount--;
  }

  decreaseFreeBetsCount() {
    this.freeBetsCount--;
  }

  increaseBuyBonusCount(betIndex) {
    if (betIndex >= 0) this._data.buyBonusCountByIndex[betIndex] = (this._data.buyBonusCountByIndex[betIndex] + 1) || 1;
    this.buyBonusCount++;
  }

  decreaseBuyBonusCount(betIndex) {
    if (betIndex >= 0) this._data.buyBonusCountByIndex[betIndex] = (this._data.buyBonusCountByIndex[betIndex] - 1) || 0;
    this.buyBonusCount--;
  }

  downloadStopped() {
    this._downloadEndTime = Date.now();
    this._data.downloadTime = this._downloadEndTime - this._downloadStartTime;
    this._gameIsdownload = true;
    this.sendDownloadCompleteData();
    this.onUpdate.call(this.statistic);
  }

  setOrientation(orientation) {
    if (!this._orientation) this._orientation = orientation;
    this.stopWatchOrientation();
    this._orientation = orientation;
  }

  stopWatchOrientation() {
    if (!this._orientation) return;

    const now = Date.now();
    this._data.orientationTimes[this._orientation] = this._data.orientationTimes[this._orientation] + now - this._orintationStartTime;
    this._orintationStartTime = now;
  }

  get statistic() {
    this.stopWatchOrientation();
    this._data.sessionTime = Date.now() - this._downloadStartTime;
    return this._data;
  }

  set win(value) {
    if (this._winLimit && value > this._winLimit) {
      value = this._winLimit;
    }

    this._data.lastWinAmount = value || this._data.lastWinAmount;
    this._data.totalWinAmount += value;
    this.onUpdate.call(this.statistic);
  }

  get totalWin() {
    return this._data.totalWinAmount;
  }

  get lastWin() {
    return this._data.lastWinAmount;
  }

  set bet(value) {
    if (this._isFirstBet) this.sendFirstBet(value);
    this._isFirstBet = false;
    this._data.lastBetAmount = value;
    this._data.totalBetAmount += value;
    this.onUpdate.call(this.statistic);
  }

  set canceledBet(value) {
    this._data.totalBetAmount -= value;
    this.onUpdate.call(this.statistic);
  }

  get totalBet() {
    return this._data.totalBetAmount;
  }

  get lastBet() {
    return this._data.lastBetAmount;
  }

  set spinCount(value) {
    this._data.spinCount = value;
    this.onUpdate.call(this.statistic);
  }

  get spinCount() {
    return this._data.spinCount;
  }
  set autoBetCount(value) {
    this._data.autoBetCount = value;
    this.onUpdate.call(this.statistic);
  }

  get autoBetCount() {
    return this._data.autoBetCount;
  }

  set freeBetsCount(value) {
    this._data.freeBetsCount = value;
    this.onUpdate.call(this.statistic);
  }

  get freeBetsCount() {
    return this._data.freeBetsCount;
  }

  set buyBonusCount(value) {
    this._data.buyBonusCount = value;
    this.onUpdate.call(this.statistic);
  }

  get buyBonusCount() {
    return this._data.buyBonusCount;
  }

  get downloadTime() {
    return this._data.downloadTime;
  }

  set educationComplete(value) {
    return this._data.educationComplete = value;
  }

  get educationComplete() {
    return this._data.educationComplete;
  }

  set educationStepsCount(step) {
    this._data.educationStepCount = step;
  }

  get educationStepsCount() {
    return this._data.educationStepCount;
  }

  set onUpdate(callback) {
    this._onUpdate.add(callback);
  }

  get onUpdate() {
    return this._onUpdate;
  }

  get bundle() {
    return this._bundle;
  }

  set bundle(value) {
    this._bundle = value;
  }
}
