import FlashLib from 'flashlib';
import ControllerButton from './controllers/base/controllerButton';
import ControllerFieldCell from './controllers/controllerFieldCell';
import ControllerPopupRules from './controllers/popups/popupRules';
import ControllerPopupWin from './controllers/popups/popupWin';
import ControllerPopupBigWin from './controllers/popups/popupBigWin';
import ControllerLevelSelector from './controllers/controllerLevelSelector';
import ControllerLevelPrize from './controllers/controllerLevelPrize';
import ControllerButtonMenu from './controllers/buttonMenu';
import ControllerBalanceView from './controllers/controllerBalanceView';
import ControllerLogo from './controllers/ControllerLogo';

FlashLib.registerClass('Button', ControllerButton);
FlashLib.registerClass('FieldCell', ControllerFieldCell);
FlashLib.registerClass('ControllerPopupRules', ControllerPopupRules);
FlashLib.registerClass('ControllerPopupBigWin', ControllerPopupBigWin);
FlashLib.registerClass('ControllerPopupWin', ControllerPopupWin);
FlashLib.registerClass('ControllerLevelSelector', ControllerLevelSelector);
FlashLib.registerClass('ControllerLevelPrize', ControllerLevelPrize);
FlashLib.registerClass('ControllerButtonMenu', ControllerButtonMenu);
FlashLib.registerClass('ControllerBalanceView', ControllerBalanceView);
FlashLib.registerClass('ControllerLogo', ControllerLogo);
