export default {
  EUN_BASE_RULES_HTML: 'baseRulesHTML',
  EUN_BASE_RULES_PDF: 'baseRulesPDF',
  EUN_BASE_FRAME_URL: 'baseFrameURL',
  EUN_ROUNDS_HISTORY_API: 'roundsHistoryApi',
  EUN_LOCALIZATION_URL : 'requestLocalizationUrl',
  EUN_FALLBACK_LOCALIZATION_URL : 'fallbackLocalizationUrl',
  EUN_WSS_TOURNAMENTS_URL: 'wssTournamentsURL',
  EUN_WSS_STATISTIC_URL: 'StatisticURL',
  EUN_RESULTS_SERVICE_ORIGIN: 'resultsServiceOrigin'
}
