import actions from '../redux/game/actions';
import actionsModals from '../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import ControllerMock from './controllerMock';
import { store } from '../index';
import eModalType from '../controllers/popups/eModalType';
import selectors from '../redux/game/selectors';

const enableMock = false;

const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=luckyocean&lang=az&no_redirect&no_op_launcher',
  baseData: {
    ver: '1.1'
  }
};

export const requestConfig = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();

  const response = enableMock ?
    await ControllerMock.requestConfig() :
    await sendRequest({ }, 'config');

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  store.dispatch(actions.setData(response));
};

export const requestNewGame = async (betIndex) => {
  const data = {
    betIndex,
  };

  let response = enableMock ?
    await ControllerMock.requestNewGame(amount) :
    await sendRequest(data, 'new_game');
  if (!response) return;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setNewGame(response));
};

export const requestFieldOpen = async (index) => {
  const data = {
    index,
  };

  let response = enableMock ?
    await ControllerMock.requestFieldOpen(index) :
    await sendRequest(data, 'open');
  if (!response) return;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  if (response.is_win) {
    store.dispatch(actions.setDataFieldOpenWin(response));
    if (response.steps === 6) {
      requestTakeGame(store.dispatch);
      ControllerSounds.playSound(eSoundType.EST_BIG_WIN);
    } else {
      ControllerSounds.playSound(eSoundType.EST_WIN);
    }
  } else {
    window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    store.dispatch(actions.setDataGameEnd(response));
    ControllerSounds.playSound(eSoundType.EST_LOSE);
  }
};

export const requestTakeGame = async () => {
  const response = enableMock ?
    await ControllerMock.requestTakeGame() :
    await sendRequest({}, 'collect');
  if (!response) return;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  let state = store.getState();
  window.OPWrapperService.ControllerStatistic.win = selectors.getWinAmount(state);
  window.OPWrapperService.realityCheck.blockedRealityCheck = false;

  store.dispatch(actions.setDataGameEnd(response));
  store.dispatch(actions.openOtherCells());

  ControllerSounds.playSound(eSoundType.EST_COINS);
  let modalType;
    if (response.steps === 6){
      modalType = eModalType.EMT_WIN_BIG;
    } else {
      modalType = eModalType.EMT_WIN_REGULAR;
      window.OPWrapperService.freeBetsController.updateTotalWin();
    }
  store.dispatch(actionsModals.showModal({ type: modalType }));
};

let waitingForResponse = false;
const sendRequest = async (data, id, urlPath = 'game_request') => {
  if (waitingForResponse) {
    console.warn('Already waiting for response.');
    return;
  }
  data.id = id;
  waitingForResponse = true;
  const response = await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
  waitingForResponse = false;
  return response;
};
