import { getUrlParam } from '../utils/url';
import eURLsNames from './eURLsNames';
import gamesProviderConfig from './gamesProviderConfig'


export default new class URLsManager {
  constructor() {

    let domainPrefix = '';
    let path = '';
    let prefix = '';
    let env = '';
    if (/.stage/.test(getUrlParam('api'))) {
      prefix = 'stage.';
      env = 'stage';
    } else if (/.dev/.test(getUrlParam('api'))) {
      prefix = 'dev.';
      env = 'dev'
    }

    if (env) {
      domainPrefix = `${env}-`;
      path = `/${env}`;
    }

    this.URLs = {
      [eURLsNames.EUN_BASE_RULES_PDF]: `https://res.${gamesProviderConfig.baseDomain}.net/rules`,
      [eURLsNames.EUN_BASE_RULES_HTML]: `https://${domainPrefix}res.${gamesProviderConfig.baseDomain}.net/rulesV2${path}`,
      [eURLsNames.EUN_BASE_FRAME_URL]: `https://pdf-viewer.${gamesProviderConfig.baseDomain}.net`,
      [eURLsNames.EUN_ROUNDS_HISTORY_API]: `https://rounds-history.dev.${gamesProviderConfig.igDomain}.net/api/`,
      [eURLsNames.EUN_LOCALIZATION_URL]:`https://i18n.${gamesProviderConfig.lacalizationDomain}.net/localizations/{bundle}/{lang}.json`,
      [eURLsNames.EUN_FALLBACK_LOCALIZATION_URL]: `https://i18n.${gamesProviderConfig.baseDomain}.net/localizations/{bundle}/{lang}.json`,
      [eURLsNames.EUN_WSS_TOURNAMENTS_URL]: `wss://tournaments.${prefix}${gamesProviderConfig.baseDomain}.net/info/websocket`,
      [eURLsNames.EUN_WSS_STATISTIC_URL]: `wss://ws-live-statistics.${prefix}${gamesProviderConfig.baseDomain}.net/stat`,
      [eURLsNames.EUN_RESULTS_SERVICE_ORIGIN]: `https://round-results.${gamesProviderConfig.baseDomain}.net`
    }
  }

  get eURLsNames(){
    return eURLsNames;
  }
  getURL(key, params) {
    let url = this.URLs[key];
    if (params) {
      params.forEach((param, index) => {
        url = url.replace(`{${index}}`, param);
      });
    }
    return url;
  }
}
