import FlashLib from 'flashlib';

export default class ControllerLogo extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this._brandedLanguage = "az";
    this._init();
  }

  _init(){
    if(OPWrapperService.localizations.currentLanguage ===  this._brandedLanguage){
      this.goToFrame(2)
    }
  }
}
